import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/orders',
    name: 'OrdersView',
    component: function () {
      if(isOperator() || isAdmin() || isSuperviser()) {
        return import('../views/OrdersView.vue')
      } 
        
      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/order/:order_id',
    name: 'OrderView',
    component: function () {
      if(isOperator() || isAdmin() || isSuperviser()) {
        return import('../views/OrderView.vue')
      } 
        
      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/tariffs',
    name: 'TariffsView',
    component: function () {
      if(isManager() || isAdmin() || isSuperviser()) {
        return import('../views/TariffsView.vue')
      } 
        
      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/markets',
    name: 'MarketsView',
    component: function () {
      if(isAdmin() || isSuperviser()) {
        return import('../views/MarketsView.vue')
      } 
        
      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/directions',
    name: 'DirectionsView',
    component: function () {
      if(isSuperviser() || isAdmin()) {
        return import('../views/DirectionsView.vue')
      } 
        
      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/pairs',
    name: 'PairsView',
    component: function () {
      if(isManager() || isAdmin() || isSuperviser()) {
        return import('../views/PairsView.vue')
      } 
        
      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/balances',
    name: 'BalancesView',
    component: function () {
      if(isAdmin()) {
        return import('../views/BalancesView.vue')
      } 
        
      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/cards',
    name: 'CardsView',
    component: function () {
      if(isAdmin()) {
        return import('../views/CardsView.vue')
      } 
        
      return import('../views/HomeView.vue')
    }
  },
  {
    path: '/users',
    name: 'UsersView',
    component: function () {
      if(isAdmin()) {
        return import('../views/UsersView.vue')
      } 
        
      return import('../views/HomeView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function isAdmin() {
  var rights = localStorage.getItem('sbe_user_rights');

  if(rights && rights.includes('admin')) {
    console.log("** IS ADMIN")
    return true
  }
  return false
}

function isManager() {
  var rights = localStorage.getItem('sbe_user_rights');

  if(rights && rights.includes('manager')) {
    console.log("** IS MANAGER")
      return true
  }
  return false
}

function isOperator() {
  var rights = localStorage.getItem('sbe_user_rights');

  if(rights && rights.includes('operator')) {
    console.log("** IS OPERATOR")
      return true
  }
  return false
}

function isSuperviser() {
  var rights = localStorage.getItem('sbe_user_rights');

  if(rights && rights.includes('superviser')) {
    console.log("** IS OPERATOR")
      return true
  }
  return false
}

export default router
