import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import './assets/bootstrap.min.css'

axios.defaults.baseURL = process.env.VUE_APP_API;
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

if (localStorage) {
    console.log('** APP STORAGE')
    var token = localStorage.getItem('sbe_user_token');

    console.log('** APP TOKEN ' + token)
    if(token != null) {
        var expired = isTokenExpired(token)
        console.log('** APP TOKEN2 ' + expired)

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    
}

axios.interceptors.request.use(function (config) {
    console.log("AXIOS 1")

    // Do something before request is sent
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    console.log("AXIOS 2")
    if(response.status === 401) {
        console.log("*** NEED AUTH")
        logout() 
    }

    return response;
}, function (error) {
    console.log("*** NEED AUTH")
    if(error.response.status == 401) {
        logout() 
    }
    return Promise.reject(error);
});


function logout() {
    localStorage.removeItem('sbe_user_token');
    localStorage.removeItem('sbe_user_rights')
    localStorage.removeItem('sbe_user_profile_username')
    localStorage.removeItem('sbe_user_profile_firstname')
    localStorage.removeItem('sbe_user_profile_lastname')
    window.dispatchEvent(new CustomEvent('logout-data-localstorage-event', {

    }));
}

function isTokenExpired(token) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
}

setInterval(async() => { 
    var token = localStorage.getItem('sbe_user_token');
    if(token != null) {
        var expired = isTokenExpired(token)
        if(expired) {
            logout()
        }
    }
}, 5000)

console.log("MAIN APP")
createApp(App).use(router).mount('#app')
