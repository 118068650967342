<template>
    <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-dark" aria-label="Main navigation">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">SBE</a>
            <button class="navbar-toggler p-0 border-0" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item" v-if="isAdmin || isOperator || isSuperviser">
                        <a class="nav-link" href="/orders" :class="selected == 'orders' ? 'active' : ''">Orders</a>
                    </li>
                    <li class="nav-item" v-if="isAdmin || isManager || isSuperviser">
                        <a class="nav-link" href="/tariffs" :class="selected == 'tariffs' ? 'active' : ''">Tariffs Schedule</a>
                    </li>
                    <li class="nav-item" v-if="isAdmin || isSuperviser">
                        <a class="nav-link" href="/directions" :class="selected == 'directions' ? 'active' : ''">Directions</a>
                    </li>
                    <li class="nav-item" v-if="isAdmin || isManager || isSuperviser">
                        <a class="nav-link" href="/pairs" :class="selected == 'pairs' ? 'active' : ''">Pairs</a>
                    </li>
                    <li class="nav-item" v-if="isAdmin || isSuperviser">
                        <a class="nav-link" href="/markets" :class="selected == 'markets' ? 'active' : ''">Markets</a>
                    </li>
                    <li class="nav-item" v-if="isAdmin">
                        <a class="nav-link" href="/balances" :class="selected == 'balances' ? 'active' : ''">Balances</a>
                    </li>
                    <li class="nav-item" v-if="isAdmin">
                        <a class="nav-link" href="/cards" :class="selected == 'cards' ? 'active' : ''">Cards</a>
                    </li>
                    <li class="nav-item" v-if="isAdmin">
                        <a class="nav-link" href="/users" :class="selected == 'users' ? 'active' : ''">Users</a>
                    </li>
                </ul>
                <div class="d-flex" role="search">  
                    <nav class="d-inline-flex mt-2 mt-md-0 ms-md-auto">
                        <a class="me-3 py-2 text-decoration-none text-light" href="#">{{ username }}</a>
                    </nav>   
                    <button v-on:click="logout()" class="btn btn-outline-success" type="submit">Logout</button>
                </div>
            </div>
        </div>
    </nav>

    <main class="main-conteiner">
        <slot></slot>
    </main>
</template>

<style>
.main-conteiner {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
}
</style>

<script>
export default {
    props: {
        selected: String
    },
    data() {
      return {
        _page: 1,
        _orders: [],
      }
    },
    methods: {
        logout() {
            localStorage.removeItem('sbe_user_token');
            localStorage.removeItem('sbe_user_rights')
            localStorage.removeItem('sbe_user_profile_username')
            localStorage.removeItem('sbe_user_profile_firstname')
            localStorage.removeItem('sbe_user_profile_lastname')
            window.dispatchEvent(new CustomEvent('logout-data-localstorage-event', {

            }));
        }
    },
    computed: {
        enabledFull: function() {
            var rights = localStorage.getItem('sbe_user_rights');

            if(rights && rights.includes('admin')) {
                return true
            }
            return false
        },
        isManager: function() {
            var rights = localStorage.getItem('sbe_user_rights');

            if(rights && rights.includes('manager')) {
                return true
            }
            return false
        },
        isAdmin: function() {
            var rights = localStorage.getItem('sbe_user_rights');

            if(rights && rights.includes('admin')) {
                return true
            }
            return false
        },
        isSuperviser: function() {
            var rights = localStorage.getItem('sbe_user_rights');

            if(rights && rights.includes('superviser')) {
                return true
            }
            return false
        },
        isOperator: function() {
            var rights = localStorage.getItem('sbe_user_rights');

            if(rights && rights.includes('operator')) {
                return true
            }
            return false
        },
        username: function() {
            let user_profile_username = localStorage.getItem('sbe_user_profile_username');
            let user_profile_firstname = localStorage.getItem('sbe_user_profile_firstname');
            let user_profile_lastname = localStorage.getItem('sbe_user_profile_lastname');

            return `${user_profile_username} (${user_profile_firstname} ${user_profile_lastname})`
        }
    },
    async mounted() {
      
    }
  }
</script>